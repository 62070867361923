import React, { useEffect, useState } from 'react';
import { PageTitle, SaveButton, saveStatuses, saveTypes } from 'ht-gui';
import { getClasses } from 'ht-resize';
import { useNavigate } from 'react-router-dom';

import { Translate } from '@hanssens/ht-translate';
import adminApi from '../../api/adminApi';

import ApplicationGeneral from '../../components/General/Applications/ApplicationCreate/ApplicationGeneral';
import { verifyObject } from '../../utilities/verificator';
import { applicationRules } from '../../components/General/Applications/ApplicationCreate/verificationRules';
import { routerPrefix } from '../../routers/MainRouter';

const ApplicationCreate = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const navigate = useNavigate();

    const [application, setApplication] = useState({
        id: null,
        name: '',
        description: '',
    });
    const [status, setStatus] = useState({
        saveStatus: saveStatuses.NEUTRAL,
    });
    const [error, setError] = useState(false);

    /*********************************************************************/
    /* Save & cancel functions
    /*********************************************************************/

    useEffect(() => {
        if (status.saveStatus === saveStatuses.SAVE_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.SAVING,
            });

            // Verify
            if (!verifyObject(application, applicationRules)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            adminApi()
                .post('v1/rights/application', application)
                .then((response) => {
                    navigate(`${routerPrefix}/applications`);

                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.SAVED,
                    });
                })
                .catch((error) => {
                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.ERROR,
                    });
                });
        }
    }, [status, navigate, application]);

    useEffect(() => {
        if (status.saveStatus === saveStatuses.CANCEL_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.CANCELING,
            });

            navigate(`${routerPrefix}/applications`);
        }
    }, [status, navigate]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/

    const handleSaveStatusChanged = (s) => {
        setStatus({
            ...status,
            saveStatus: s,
        });
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    return (
        <section id='appadmin-application-create' className='main-scrollable p-4'>
            <PageTitle title={<Translate id='appAdmin.applications.create.title' />} subTitle={<Translate id='appAdmin.applications.create.subtitle' />} />

            <div className='row'>
                <ApplicationGeneral application={application} setApplication={setApplication} status={status} error={error} />

                <div className='col-12'>
                    <div className='bg-white box-shadow p-3 mt-3'>
                        <div className='row'>
                            <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                                <div className='w-50'>
                                    <SaveButton showFeedback type={saveTypes.CONTROLLED} status={status.saveStatus} setStatus={handleSaveStatusChanged} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ApplicationCreate;
