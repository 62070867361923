import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Display of a property and it's value
 * It's also possible to add a link to an URI or to history
 * @param {*} props
 */
const Property = (props) => {
    const navigate = useNavigate();
    const classNames = ['mb-3'];
    const contentClassNames = [];

    let content = null;

    /********************************************
     * Event handlers
     ********************************************/

    /**
     * Handle when the link is clicked
     * @param {ClickEvent} e Click event on the link
     */
    const handleLinkClicked = (e) => {
        if (props.onClick) {
            props.onClick(e);
        } else if (props.pushToHistory) {
            e.preventDefault();
            navigate(e.target.href);
        }
    };

    /********************************************
     * Render
     ********************************************/

    // When there is no value passed we won't render it
    if (props.value === null || props.value === undefined || props.value === '') {
        return null;
    }

    // Set the property content
    content = props.value;

    // Check if there is a prefix provided
    if (props.prefix !== null && props.prefix !== undefined) {
        content = props.prefix + ' ' + content;
    }

    // Cech if there is a suffix proviced
    if (props.suffix !== null && props.suffix !== undefined) {
        content = content + ' ' + props.suffix;
    }

    // Check if there is a link the prop needs to pass to
    if (props.link !== null && props.link !== undefined) {
        content = (
            <a href={props.link} target='_blank' onClick={handleLinkClicked} rel='noopener noreferrer'>
                {content}
            </a>
        );
    }

    // Add the custom content classnames
    if (props.contentClassNames !== undefined && props.contentClassNames !== null) {
        contentClassNames.push(props.contentClassNames);
    }

    // Add the additional proprty classnames
    if (props.classNames !== undefined && props.classNames !== null) {
        classNames.push(props.classNames);
    }

    return (
        <div className={classNames.join(' ')}>
            <div className='small'>{props.label}</div>
            <div className={contentClassNames.join(' ')}>{content}</div>
        </div>
    );
};

export default Property;
