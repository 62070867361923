import React, { useEffect } from 'react';
import { PageTitle } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';
import { useStore } from 'ht-store';
import { useNavigate } from 'react-router-dom';

import ApplicationsOverview from '../components/General/Applications/ApplicationsOverview';

const Applications = (props) => {
    const [breadcrumbs, setBreadcrumbs] = useStore('appadmin-breadcrumbs');
    const navigate = useNavigate();

    useEffect(() => {
        setBreadcrumbs([{ label: <Translate id='appAdmin.applications.breadcrumbs.applications' /> }]);
    }, [setBreadcrumbs]);

    const handleBreadcrumClicked = (e, route) => {
        e.preventDefault();
        navigate(route);
    };

    return (
        <section id='appAdmin-applications-overview' className='main-scrollable p-4'>
            <PageTitle
                title={<Translate id='appAdmin.applications.title' />}
                subTitle={<Translate id='appAdmin.applications.subtitle' />}
                breadcrumbs={breadcrumbs}
                onLinkClicked={handleBreadcrumClicked}
            />
            <div className=''>
                <ApplicationsOverview />
            </div>
        </section>
    );
};

export default Applications;
