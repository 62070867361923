import React from 'react';
import { Input, TitleBar, saveStatuses } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';
import { getClasses } from 'ht-resize';

import { verifyProperty, verifyObject } from '../../../../utilities/utility';
import { checkIfURIStartsWithAPI } from '../../ApplicationSettings/ApplicationSettingCreate/ApplicationSettingInputRegex';

import { applicationPartRules } from './verificationRules';

import Property from '../../../Shared/Property';

const ApplicationPartGeneral = (props) => {
    const { applicationPart, setApplicationPart, status, error, applicationId, edit } = props;

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    let id = null;

    if (edit && applicationPart.id) {
        id = <Property label={<Translate id='appAdmin.applications.id' />} value={applicationPart.id} />;
    }

    return (
        <div className='col-12'>
            <div className='bg-white box-shadow'>
                <TitleBar label={<Translate id='appAdmin.applications.create.general' />} />
                <div className='p-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                            {id}
                            <Property label={<Translate id='appAdmin.applications.applicationId' />} value={applicationId} />
                            <Input
                                id='appAdmin.applications.name'
                                value={applicationPart.name}
                                label={<Translate id='appAdmin.applications.name' />}
                                error={(error && !verifyProperty(applicationPart.name, applicationPartRules.name)) || (applicationPart.name && applicationPart.name.length > 90)}
                                required
                                onChange={(e) => setApplicationPart({ ...applicationPart, name: e.target.value })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                            <Input
                                id='appAdmin.applications.syncUri'
                                value={applicationPart.syncURI}
                                label={<Translate id='appAdmin.applications.syncUri' />}
                                error={error && applicationPart.syncURI && !checkIfURIStartsWithAPI(applicationPart.syncURI)}
                                onChange={(e) => setApplicationPart({ ...applicationPart, syncURI: e.target.value })}
                                className='w-100'
                                errorMessage={<Translate id='appAdmin.applications.uris.uriError' />}
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                        </div>
                    </div>

                    {/* Error */}
                    {error && !verifyObject(applicationPart, applicationPartRules) ? (
                        <div className='row'>
                            <div className='col-6 '>
                                <div className='alert alert-danger rounded-0 mb-0 mt-3'>
                                    <Translate id='appAdmin.applications.create.error' />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ApplicationPartGeneral;
