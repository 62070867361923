import React from 'react';

const CheckDisplay = ({ checked }) => {
    if (checked) {
        return <i className='fas fa-check text-success' />;
    }

    return <i className='fas fa-times text-danger' />;
};

export default CheckDisplay;
