import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTranslate } from '@hanssens/ht-translate';

// Translations
import applicationTranslations from '../translations/general/applications.json';

// Components
import Applications from '../pages/Applications';
import ApplicationEdit from '../pages/Applications/ApplicationEdit';
import ApplicationCreate from '../pages/Applications/ApplicationCreate';
import ApplicationDashboard from '../pages/Applications/ApplicationDashboard';
import ApplicationParts from '../components/General/Applications/ApplicationDashboard/ApplicationParts';
import ApplicationRights from '../components/General/Applications/ApplicationDashboard/ApplicationRights';
import ApplicationUris from '../components/General/Applications/ApplicationDashboard/ApplicationUris';

import ApplicationPartCreate from '../components/General/ApplicationParts/ApplicationPartCreate';
import ApplicationPartEdit from '../components/General/ApplicationParts/ApplicationPartEdit';

import ApplicationUriCreate from '../components/General/ApplicationUris/ApplicationUriCreate';
import ApplicationUriEdit from '../components/General/ApplicationUris/ApplicationUriEdit';

import ApplicationRightCreate from '../components/General/ApplicationRights/ApplicationRightCreate';
import ApplicationRightEdit from '../components/General/ApplicationRights/ApplicationRightEdit';

import ApplicationUriRights from '../pages/Applications/ApplicationUris/ApplicationUriRights';
import ApplicationUriRightsOverview from '../components/General/ApplicationUris/ApplicationUriRightsOverview';
import ApplicationUriRightCreate from '../components/General/ApplicationUris/ApplicationUriRights/ApplicationUriRightCreate';
import ApplicationUriRightEdit from '../components/General/ApplicationUris/ApplicationUriRights/ApplicationUriRightEdit';

import ApplicationSettings from '../components/General/Applications/ApplicationDashboard/ApplicationSettings';
import ApplicationSettingCreate from '../components/General/ApplicationSettings/ApplicationSettingCreate';
import ApplicationSettingEdit from '../components/General/ApplicationSettings/ApplicationSettingEdit';

// Css
import '../css/main.scss';

export const routerPrefix = '/applications';

const ApplicationRouter = (props) => {
    /***********************************************************************
     * Startup
     ***********************************************************************/
    useTranslate(applicationTranslations);

    /***********************************************************************
     * Render
     ***********************************************************************/

    // Return the routes
    return (
        <div className='ht-app-applications'>
            <Routes>
                {/* Update */}
                <Route path='update/:id/*' element={<ApplicationEdit />} />

                {/* Create */}
                <Route path='create/*' element={<ApplicationCreate />} />

                {/* Uri rights */}
                <Route path='/:id/dashboard/uris/:uriId/rights' element={<ApplicationUriRights />}>
                    <Route index element={<ApplicationUriRightsOverview />} />
                    <Route path='update/:rightId' element={<ApplicationUriRightEdit />} />
                    <Route path='create' element={<ApplicationUriRightCreate />} />
                </Route>

                {/* Dasboard */}
                <Route path='/:id/dashboard/' element={<ApplicationDashboard />}>
                    <Route index element={'Nothing selected'} />

                    <Route path='parts/update/:partId' element={<ApplicationPartEdit />} />
                    <Route path='parts/create' element={<ApplicationPartCreate />} />
                    <Route path='parts' element={<ApplicationParts />} />

                    <Route path='rights/update/:rightId' element={<ApplicationRightEdit />} />
                    <Route path='rights/create' element={<ApplicationRightCreate />} />
                    <Route path='rights' element={<ApplicationRights />} />

                    <Route path='uris/update/:uriId' element={<ApplicationUriEdit />} />
                    <Route path='uris/create' element={<ApplicationUriCreate />} />
                    <Route path='uris' element={<ApplicationUris />} />

                    <Route path='settings/update/:settingId' element={<ApplicationSettingEdit />} />
                    <Route path='settings/create' element={<ApplicationSettingCreate />} />
                    <Route path='settings' element={<ApplicationSettings />} />
                </Route>

                {/* Overview */}
                <Route path='/' element={<Applications />} />

                {/* Redirect */}
                <Route path='*' element={<Navigate to='/appadmin/applications' />} />
            </Routes>
        </div>
    );
};

export default ApplicationRouter;
