import React from 'react';
import withResize from 'ht-resize';
import { Route, Routes } from 'react-router-dom';
import { useTranslate } from '@hanssens/ht-translate';
import { useStore } from 'ht-store';

// Translations
import globalTranslations from '../translations/global.json';

// Components
import ApplicationRouter from './ApplicationRouter';

// Css
import '../css/main.scss';
import 'tippy.js/dist/tippy.css';

export const routerPrefix = '/appadmin';

const MainRouter = (props) => {
    const [auth] = useStore('auth');

    /***********************************************************************
     * Startup
     ***********************************************************************/
    useTranslate(globalTranslations);

    /***********************************************************************
     * Render
     ***********************************************************************/

    let routes = [];

    if (auth && auth.rights && auth.rights.length > 0) {
        if (auth.rights.includes('user.management.access') || auth.rights.includes('*')) {
            routes.push(<Route path={'/applications/*'} element={<ApplicationRouter />} />);
        }
    }

    // Return the routes
    return (
        <div className='ht-app-app-admin'>
            <Routes>
                {/* List */}
                {routes}

                {/* Redirect */}
                {/* <Route path='*' element={<Navigate to={routerPrefix + '/overview'} replace />} /> */}
            </Routes>
        </div>
    );
};

export default withResize()(MainRouter);
