import React from 'react';
import { Translate, useTranslate } from '@hanssens/ht-translate';
import { routerPrefix } from '../../../routers/MainRouter';
import { routerPrefix as applicationPrefix } from '../../../routers/ApplicationRouter';

import sidebarLeftTranslations from '../../../translations/layout/sidebarLeft.json';

const SidebarLeft = ({ first, container, onClick }) => {
    // Add translation
    useTranslate(sidebarLeftTranslations);

    /********************************/
    /* Render
    /********************************/
    // Define config
    let config = [
        {
            link: `${routerPrefix}${applicationPrefix}`,
            label: <Translate id='appAdmin.applications.sidebarLeft.overview' />,
            icon: 'fal fa-display-code',
            hoverIcon: 'fas fa-display-code',
        },
    ];

    let SidebarItemsContainer = container;
    return <SidebarItemsContainer first={first} config={config} onClick={onClick} title={<Translate id='appAdmin.admin.sidebarLeft.title' />} />;
};

export default SidebarLeft;
