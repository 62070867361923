/*********************************************************************/
/* Function
    /*********************************************************************/
export const checkIfInputIsValidName = (input) => {
    return /^[A-Z]+(?:_*[A-Z]+)*$/.test(input);
};

export const checkIfURIStartsWithAPI = (input) => {
    return /^\/api\/v[0-9]+\//.test(input);
};
