import React, { useCallback, useEffect, useState } from 'react';
import { TitleBar, ManageTable, NoContent, ManageTableActions } from 'ht-gui';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Tippy from '@tippyjs/react';

import Error from '../../Shared/Error';
import Loading from '../../Shared/Loading';

import adminApi from '../../../api/adminApi';
import { Translate } from '@hanssens/ht-translate';

import { routerPrefix } from '../../../routers/MainRouter';
import { routerPrefix as applicationPrefix } from '../../../routers/ApplicationRouter';
import DeleteModal from '../../Shared/DeleteModal';

const UrisOverview = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [deletePopup, setDeletePopup] = useState(null);
    const [refreshingCache, setRefreshingCache] = useState(false);

    const navigate = useNavigate();

    /*********************************************************************/
    /* Init
    /*********************************************************************/

    const fetchData = useCallback(() => {
        adminApi()
            .get('v1/rights/application')
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    setError({ statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null });
                } else {
                    setError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                }
            });
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/

    const handleCreateClicked = (e) => {
        e.preventDefault();
        navigate(`${routerPrefix}${applicationPrefix}/create`);
    };

    const handleEditClicked = (data) => {
        navigate(`${routerPrefix}${applicationPrefix}/update/${data.id}`);
    };

    const handleDeleteClicked = (data) => {
        setDeletePopup({ object: data });
    };

    const handleInfoClicked = (data) => {
        navigate(`${routerPrefix}${applicationPrefix}/${data.id}/dashboard/rights`);
    };

    const handleDoubleClicked = (e, data) => {
        e.preventDefault();
        navigate(`${routerPrefix}${applicationPrefix}/${data.id}/dashboard/rights`);
    };

    const handleConfirmDeleteClicked = (object, onSuccess, onError) => {
        adminApi()
            .delete(`v1/rights/application/${object.id}`)
            .then((response) => {
                let currentApplications = [...data];
                let index = currentApplications.findIndex((t) => t.id === object.id);

                if (index === -1) {
                    return;
                }

                currentApplications.splice(index, 1);

                setData(currentApplications);
                onSuccess();
            })
            .catch((error) => {
                onError();
            });
    };

    const handleApplicationDeleted = (object) => {
        setDeletePopup(null);
    };

    const handleCacheRefresh = () => {
        setRefreshingCache(true);
        adminApi()
            .get('v1/rights/cache/refresh')
            .then(() => {
                setRefreshingCache(false);
            })
            .catch(() => {
                setRefreshingCache(false);
            });
    };
    /*********************************************************************/
    /* Managetable config
    /*********************************************************************/

    const componentsAfter = (
        <>
            <Button variant='contained' className='bg-success text-white rounded-0 ml-1' onClick={handleCreateClicked}>
                <Translate id='appAdmin.applications.createNew' />
            </Button>

            <Tippy content={<Translate id='appAdmin.applications.refreshCache' />}>
                <Button variant='contained' disabled={refreshingCache} className='bg-secondary text-white rounded-0 ml-1' onClick={handleCacheRefresh}>
                    {refreshingCache ? <i className='fas fa-spin fa-sync' /> : <i className='fas fa-sync' />}
                </Button>
            </Tippy>
        </>
    );

    const config = {
        columns: {
            name: {
                label: <Translate id='appAdmin.applications.name' />,
            },
            description: {
                label: <Translate id='appAdmin.applications.description' />,
            },
            actions: {
                label: 'Actions',
                component: ManageTableActions,
                props: {
                    actions: [
                        {
                            icon: 'fal fa-circle-info',
                            hoverIcon: 'fas fa-circle-info',
                            onClick: handleInfoClicked,
                        },
                        {
                            icon: 'fal fa-pencil',
                            hoverIcon: 'fas fa-pencil',
                            onClick: handleEditClicked,
                        },
                        {
                            icon: 'fal fa-trash',
                            hoverIcon: 'fas fa-trash',
                            onClick: handleDeleteClicked,
                        },
                    ],
                },
            },
        },
        search: {
            componentsAfter: componentsAfter,
        },
        noItemsComponent: (
            <NoContent
                title={<Translate id='appAdmin.applications.noEntries' />}
                description={<Translate id='appAdmin.applications.noEntriesDesc' />}
                backgroundColour='bg-dark-blue'
                icon='fas fa-shelves-empty fa-7x'
            />
        ),
        onDoubleClick: handleDoubleClicked,
        data: data,
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    // Error
    if (error) {
        return <Error code={error.statusCode} message={error?.data} />;
    }

    // Loading
    if (!data) {
        return <Loading title={<Translate id='appAdmin.applications.loading' />} description={<Translate id='appAdmin.applications.loadingDesc' />} />;
    }

    // No entries
    // if (data && data.length === 0) {
    //     return (
    //         <NoContent
    //             title={<Translate id='appAdmin.applications.noEntries' />}
    //             description={<Translate id='appAdmin.applications.noEntriesDesc' />}
    //             backgroundColour='bg-dark-blue'
    //             icon='fas fa-shelves-empty fa-7x'
    //         />
    //     );
    // }

    let deleteModalContent = null;
    if (deletePopup) {
        let description = (
            <>
                <Translate id='appAdmin.applications.delete.areYouSureDelete' />
                <div>
                    <div>{deletePopup.object.name}</div>
                </div>
            </>
        );
        deleteModalContent = (
            <DeleteModal
                object={deletePopup.object}
                onConfirmClicked={handleConfirmDeleteClicked}
                title={<Translate id='appAdmin.applications.delete.title' />}
                description={description}
                failed={<Translate id='appAdmin.applications.delete.failed' />}
                titleBarColor='bg-danger'
                confirmClasses={['bg-danger', 'text-white']}
                onClose={() => setDeletePopup(null)}
                onDeleted={handleApplicationDeleted}
            />
        );
    }

    return (
        <>
            {deleteModalContent}

            <div className='box-shadow bg-white'>
                <TitleBar label={<Translate id='appAdmin.applications.title' />} />
            </div>

            <ManageTable {...config} />
        </>
    );
};

export default UrisOverview;
