import React, { useEffect, useState } from 'react';
import { Input, TitleBar, saveStatuses, SuggestiveSelect, NumberSpinner, Switch } from 'ht-gui';
import { Translate, useTranslate } from '@hanssens/ht-translate';
import { getClasses } from 'ht-resize';

import { verifyProperty, verifyObject } from '../../../../utilities/utility';
import adminApi from '../../../../api/adminApi';

import { applicationRightRules } from './verificationRules';

import Property from '../../../Shared/Property';

const ApplicationRightGeneral = (props) => {
    const { applicationRight, setApplicationRight, status, error, applicationId, edit } = props;
    const [applicationParts, setApplicationParts] = useState(null);
    const translate = useTranslate();

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    useEffect(() => {
        adminApi()
            .get(`v1/rights/applicationpart/application/${applicationId}`)
            .then((response) => {
                let data = [
                    ...response.data.map((d) => {
                        return {
                            value: d.id,
                            label: d.name,
                        };
                    }),
                ];

                setApplicationParts(data);
            })
            .catch((err) => {
                console.warn('An error occured during the fetch of the application parts', err);
            });
    }, [applicationId]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/
    const handleSortOrderChanged = (value) => {
        setApplicationRight({ ...applicationRight, rightSortOrder: value });
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    let id = null;

    if (edit && applicationRight.id) {
        id = <Property label={<Translate id='appAdmin.applications.id' />} value={applicationRight.id} />;
    }

    return (
        <div className='col-12'>
            <div className='bg-white box-shadow'>
                <TitleBar label={<Translate id='appAdmin.applications.create.general' />} />
                <div className='p-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                            {id}
                            <Property label={<Translate id='appAdmin.applications.applicationId' />} value={applicationId} />
                            <Input
                                id='appAdmin.applications.rights.name'
                                value={applicationRight.name}
                                label={<Translate id='appAdmin.applications.rights.name' />}
                                error={(error && !verifyProperty(applicationRight.name, applicationRightRules.name)) || (applicationRight.name && applicationRight.name.length > 90)}
                                required
                                onChange={(e) => setApplicationRight({ ...applicationRight, name: e.target.value })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                            <Input
                                id='appAdmin.applications.rights.displayName'
                                value={applicationRight.displayName}
                                label={<Translate id='appAdmin.applications.rights.displayName' />}
                                error={(error && !verifyProperty(applicationRight.displayName, applicationRightRules.displayName)) || (applicationRight.name && applicationRight.name.length > 90)}
                                required
                                onChange={(e) => setApplicationRight({ ...applicationRight, displayName: e.target.value })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                            <Input
                                id='appAdmin.applications.rights.description'
                                value={applicationRight.description}
                                label={<Translate id='appAdmin.applications.rights.description' />}
                                error={error && !verifyProperty(applicationRight.description, applicationRightRules.description)}
                                required
                                onChange={(e) => setApplicationRight({ ...applicationRight, description: e.target.value })}
                                className='w-100 mb-3'
                                rows={3}
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                            <Input
                                id='appAdmin.applications.rights.displayNameCategory'
                                value={applicationRight.displayNameCategory}
                                label={<Translate id='appAdmin.applications.rights.displayNameCategory' />}
                                error={
                                    (error && !verifyProperty(applicationRight.displayNameCategory, applicationRightRules.displayNameCategory)) ||
                                    (applicationRight.name && applicationRight.name.length > 90)
                                }
                                required
                                onChange={(e) => setApplicationRight({ ...applicationRight, displayNameCategory: e.target.value })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                            <Input
                                id='appAdmin.applications.rights.icon'
                                value={applicationRight.icon}
                                label={<Translate id='appAdmin.applications.rights.icon' />}
                                error={(error && !verifyProperty(applicationRight.icon, applicationRightRules.icon)) || (applicationRight.icon && applicationRight.icon.length > 30)}
                                required
                                onChange={(e) => setApplicationRight({ ...applicationRight, icon: e.target.value })}
                                className='w-100 mb-2'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <div className='mb-3'>
                                <Switch
                                    label={<Translate id='appAdmin.applications.rights.allowAllParts' />}
                                    id='appAdmin.applications.rights.allowAllParts'
                                    checked={applicationRight.allowAllParts}
                                    onChange={(e) => setApplicationRight({ ...applicationRight, allowAllParts: e.target.checked })}
                                    disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                                />
                            </div>

                            <SuggestiveSelect
                                id='appAdmin.applications.rights.applicationpart'
                                value={applicationRight.applicationPartId}
                                options={applicationParts ? [{ value: null, label: translate('appAdmin.applications.rights.create.noAppPart') }, ...applicationParts] : []}
                                onChange={(event, value) => setApplicationRight({ ...applicationRight, applicationPartId: value })}
                                label={<Translate id='appAdmin.applications.rights.applicationPart' />}
                                className='w-100 mb-3'
                                disabled={!applicationParts || status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                                error={error && !applicationRight.applicationPartId && applicationRight.allowAllParts}
                            />

                            <div className='fw-bold mb-1'>
                                <Translate id='appAdmin.applications.rights.sortOrder' />
                            </div>
                            <NumberSpinner
                                id='appAdmin.applications.rights.sortOrder'
                                value={applicationRight.rightSortOrder}
                                onChange={handleSortOrderChanged}
                                minimum={0}
                                offset={1}
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                        </div>
                    </div>

                    {/* Error */}
                    {error && !verifyObject(applicationRight, applicationRightRules) ? (
                        <div className='row'>
                            <div className='col-6 '>
                                <div className='alert alert-danger rounded-0 mb-0 mt-3'>
                                    <Translate id='appAdmin.applications.create.error' />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ApplicationRightGeneral;
