import * as rules from './verificationRules';

export const verifyObject = (objectToVerify, verificationRules) => {
    let success = true;

    Object.keys(objectToVerify).map((key) => {
        // Check if the property has verification rules
        if (!verificationRules.hasOwnProperty(key)) return null;

        // Verify the property
        if (!verifyProperty(objectToVerify[key], verificationRules[key])) {
            success = false;
        }

        return null;
    });

    return success;
};

export const verifyProperty = (property, verificationRules) => {
    let rulesToVerify = [];
    if (verificationRules) {
        rulesToVerify = verificationRules;
    }

    for (let i = 0; i < rulesToVerify.length; i++) {
        switch (rulesToVerify[i]) {
            case rules.NOT_NULL:
                if (property === null || property === undefined) {
                    return false;
                }
                break;
            case rules.NOT_EMPTY_STRING:
                if (property === '') {
                    return false;
                }
                break;
            case rules.NOT_EMPTY_ARRAY:
                if (!Array.isArray(property)) {
                    return false;
                }

                if (property.length === 0) {
                    return false;
                }
                break;
            case rules.NOT_NEGATIVE:
                // Verify if not null || undefined
                if (property === null || property === undefined) {
                    return false;
                }

                // Convert to number
                let value = Number(property);

                // Verify number
                if (value === null || value === undefined || isNaN(value)) {
                    return false;
                }

                // Verify if number is negative
                if (value < 0) {
                    return false;
                }
                break;
            default:
                break;
        }
    }

    return true;
};
