import React from 'react';

const favouriteItem = (props) => {
    // Styling for the icon based on editing or not
    let removeFavouriteIcon = <i className="remove-favourite-icon fas fa-times-circle clickable"></i>;
    if(!props.editing) {
        removeFavouriteIcon = null;
    }

    // Favourite icon
    let favouriteIconClasses = ["fas", "fa-2x", "clickable", "text-muted"];
    switch(props.type) {
        case "icon_1":  favouriteIconClasses.push("fa-ticket-alt");         break;
        case "icon_2":  favouriteIconClasses.push("fa-project-diagram");    break;
        case "icon_3":  favouriteIconClasses.push("fa-building");           break;
        default:        favouriteIconClasses.push("question");              break;
    }

    return (
        <div className="col-4 d-flex flex-column align-items-center justify-content-center">
            {removeFavouriteIcon}
            <i className={favouriteIconClasses.join(" ")}></i>
            <span>{props.name}</span>
        </div>
    );
}

export default favouriteItem;