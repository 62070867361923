import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import withResize from 'ht-resize';

import { MainRouter as TenantsRouter, routerPrefix as tenantsPrefix } from '../../library/index';

const MainRoutes = () => {
    return (
        <Routes>
            {/* General */}
            <Route path={tenantsPrefix + '/*'} element={<TenantsRouter />} />

            {/* REDIRECT */}
            <Route path='*' element={<Navigate to={tenantsPrefix} replace />} />
        </Routes>
    );
};

export default withResize()(MainRoutes);
