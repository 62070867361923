import { NOT_EMPTY_STRING, NOT_NULL } from '../../../../utilities/verificationRules';

export const applicationRightRules = {
    name: [NOT_NULL, NOT_EMPTY_STRING],
    applicationId: [NOT_NULL, NOT_EMPTY_STRING],
    displayName: [NOT_NULL, NOT_EMPTY_STRING],
    description: [NOT_NULL, NOT_EMPTY_STRING],
    displayNameCategory: [NOT_NULL, NOT_EMPTY_STRING],
    icon: [NOT_NULL, NOT_EMPTY_STRING],
    rightSortorder: [NOT_NULL, NOT_EMPTY_STRING],
};
