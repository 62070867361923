import React, { useState, useEffect } from 'react';
import { PageTitle } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useStore } from 'ht-store';

import adminApi from '../../../api/adminApi';
import Loading from '../../../components/Shared/Loading';
import Error from '../../../components/Shared/Error';

const ApplicationUriRights = (props) => {
    const { id, uriId } = useParams();
    const navigate = useNavigate();
    const [breadcrumbs] = useStore('appadmin-breadcrumbs');
    const [, setCurrentApplication] = useStore('appadmin-currentApplication');

    const [application, setApplication] = useState(null);
    const [uri, setUri] = useState(null);
    const [apiError, setApiError] = useState({
        application: null,
        uri: null,
    });

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    useEffect(() => {
        adminApi()
            .get('v1/rights/application/' + id)
            .then((response) => {
                setCurrentApplication(response.data);
                setApplication(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    setApiError((ae) => {
                        return { ...ae, application: { statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null } };
                    });
                } else {
                    setApiError((ae) => {
                        return { ...ae, application: { statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' } };
                    });
                }
            });
    }, [id, setCurrentApplication]);

    useEffect(() => {
        adminApi()
            .get('v1/rights/uri/' + uriId)
            .then((response) => {
                setUri(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    setApiError((ae) => {
                        return { ...ae, uri: { statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null } };
                    });
                } else {
                    setApiError((ae) => {
                        return { ...ae, uri: { statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' } };
                    });
                }
            });
    }, [uriId]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/
    const handleBreadcrumClicked = (e, route) => {
        e.preventDefault();
        navigate(route);
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    let title = <Translate id='appAdmin.applications.uris.title' />;
    if (application && application.name && uri) {
        title = (
            <>
                <Translate id='appAdmin.applications.uris.title' />: {uri.method} - {uri.value} ({application.name})
            </>
        );
    }

    let content = <Loading title={<Translate id='appAdmin.applications.uri.loading' />} description={<Translate id='appAdmin.applications.uri.loadingDesc' />} />;

    if (apiError.application || apiError.uri) {
        if (apiError.application) {
            content = <Error code={apiError.application.statusCode} message={apiError.application?.data} />;
        } else if (apiError.uri) {
            content = <Error code={apiError.uri.statusCode} message={apiError.uri?.data} />;
        }
    }

    if (uri) {
        content = <Outlet />;
    }

    return (
        <section id='appadmin-application-uri-rights' className='main-scrollable p-4'>
            <PageTitle title={title} subTitle={<Translate id='appAdmin.applications.uris.subtitle' />} breadcrumbs={breadcrumbs} onLinkClicked={handleBreadcrumClicked} />

            {/* Content */}
            <div className='mt-4'>{content}</div>
        </section>
    );
};

export default ApplicationUriRights;
