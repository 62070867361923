import React, { useEffect, useState } from 'react';
import { useStore } from 'ht-store';
import { Translate } from '@hanssens/ht-translate';
import { saveStatuses, SaveButton, saveTypes } from 'ht-gui';
import { useNavigate, useParams } from 'react-router-dom';
import { getClasses } from 'ht-resize';

import ApplicationRightGeneral from './ApplicationRightCreate/ApplicationRightGeneral';
import Loading from '../../Shared/Loading';
import Error from '../../Shared/Error';

import { routerPrefix } from '../../../routers/MainRouter';
import { routerPrefix as applicationPrefix } from '../../../routers/ApplicationRouter';
import adminApi from '../../../api/adminApi';
import { applicationRightRules } from './ApplicationRightCreate/verificationRules';
import { verifyObject } from '../../../utilities/utility';

const ApplicationRightEdit = (props) => {
    /***********************************************************************
     * State
     ***********************************************************************/
    const { id, rightId } = useParams();
    const navigate = useNavigate();
    const [, setBreadcrumbs] = useStore('appadmin-breadcrumbs');
    const [currentApplication] = useStore('appadmin-currentApplication');

    const [applicationRight, setApplicationRight] = useState(null);
    const [status, setStatus] = useState({
        saveStatus: saveStatuses.NEUTRAL,
    });
    const [error, setError] = useState(false);
    const [apiError, setApiError] = useState(false);

    /***********************************************************************
     * Init
     ***********************************************************************/
    useEffect(() => {
        setBreadcrumbs([
            { label: <Translate id='appAdmin.applications.breadcrumbs.applications' />, route: `${routerPrefix}${applicationPrefix}` },
            { label: currentApplication ? currentApplication.name : <Translate id='appAdmin.applications.breadcrumbs.application' /> },
            { label: <Translate id='appAdmin.applications.breadcrumbs.rights' />, route: `${routerPrefix}${applicationPrefix}/${id}/dashboard/rights` },
            { label: <Translate id='appAdmin.applications.breadcrumbs.edit' /> },
        ]);
    }, [id, setBreadcrumbs, currentApplication]);

    useEffect(() => {
        adminApi()
            .get('v1/rights/applicationright/' + rightId)
            .then((response) => {
                setApplicationRight(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    setApiError({ statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null });
                } else {
                    setApiError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                }
            });
    }, [rightId]);

    /*********************************************************************/
    /* Save & cancel functions
    /*********************************************************************/

    useEffect(() => {
        if (status.saveStatus === saveStatuses.SAVE_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.SAVING,
            });

            // Verify
            if (!verifyObject(applicationRight, applicationRightRules) || (applicationRight.allowAllParts && !applicationRight.applicationPartId)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            adminApi()
                .put(`v1/rights/applicationright/${rightId}`, applicationRight)
                .then((response) => {
                    navigate(`${routerPrefix}${applicationPrefix}/${id}/dashboard/rights`);

                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.SAVED,
                    });
                })
                .catch((error) => {
                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.ERROR,
                    });
                });
        }
    }, [status, navigate, applicationRight, rightId, id]);

    useEffect(() => {
        if (status.saveStatus === saveStatuses.CANCEL_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.CANCELING,
            });

            navigate(`${routerPrefix}${applicationPrefix}/${id}/dashboard/rights`);
        }
    }, [status, navigate, id]);

    /***********************************************************************
     * Functions
     ***********************************************************************/
    const handleSaveStatusChanged = (s) => {
        setStatus({
            ...status,
            saveStatus: s,
        });
    };

    /***********************************************************************
     * Render
     ***********************************************************************/
    // Loading
    let content = <Loading title={<Translate id='appAdmin.applications.rights.update.loading' />} description={<Translate id='appAdmin.applications.rights.update.loadingDesc' />} />;

    // Error
    if (apiError) {
        content = <Error code={apiError.statusCode} message={apiError?.data} />;
    }

    if (applicationRight) {
        content = (
            <div className='row'>
                <ApplicationRightGeneral applicationRight={applicationRight} setApplicationRight={setApplicationRight} status={status} error={error} applicationId={id} edit />
                <div className='col-12'>
                    <div className='bg-white box-shadow p-3 mt-3'>
                        <div className='row'>
                            <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                                <div className='w-50'>
                                    <SaveButton showFeedback type={saveTypes.CONTROLLED} status={status.saveStatus} setStatus={handleSaveStatusChanged} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return content;
};

export default ApplicationRightEdit;
