import axios from 'axios';
import { RIGHTS_URI, HT_TENANTID } from '../../configs/general';

const trailingSlashRegex = /\/+$/;

let instance = axios.create({
    baseURL: RIGHTS_URI.replace(trailingSlashRegex, '') + '/api/v1/rights',
    headers: {
        'ht-tenantid': HT_TENANTID,
    },
});

export default instance;
