import React from 'react';

const IconDisplay = ({ icon }) => {
    let classNames = ['fal', 'text-primary'];

    if (icon) {
        classNames.push(icon);
    } else {
        classNames.push('fa-question');
    }

    return <i className={classNames.join(' ')} />;
};

export default IconDisplay;
