import React from 'react';

const ApplicationPart = (props) => {
    const { applicationParts, applicationPartId } = props;

    if (!applicationParts || !applicationPartId) {
        return null;
    }

    let part = applicationParts.find((ap) => ap.id === applicationPartId);

    if (!part) {
        return <i className='fas fa-question' />;
    }

    return part.name;
};

export default ApplicationPart;
