import React from 'react';

const TopbarLeft = (props) => {
    let classNames = ['sidebar-right-toggle-mobile', 'fas', 'fa-caret-left', 'fa-2x', 'mr-3', 'text-dark'];
    if (props.toggled) {
        classNames.push('toggled');
    }

    return (
        <div className='bg-dark-blue text-white br-md item d-flex align-items-center'>
            {/* Toggle left */}
            <i className='fas fa-bars fa-2x ml-3 clickable' onClick={props.handleToggleLeftClicked} />

            {/* Application title */}
            <div className='mx-auto'>(icon img) Applicatie</div>

            {/* Toggle right (mobile) */}
            <div className='d-xs-flex d-lg-none clickable' onClick={props.handleToggleRightClicked}>
                <i className={classNames.join(' ')}></i>
            </div>
        </div>
    );
};

export default TopbarLeft;
