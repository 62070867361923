import { createAxiosInstance as createTenantsApi } from '../api/adminApi';

// Default configuration
const defaultConfiguration = {
    // Base props
    // API_URI: 'https://localhost:44383/',
    API_URI: 'https://api.hanssens.io/',
};

// Actual configuration
let config = { ...defaultConfiguration };

// Get the configuration
export const getConfig = () => {
    return { ...config };
};

// Set the configuration
// this must be done at the index.js
export const initialiseConfig = (newConfig) => {
    // Update config
    config = {
        ...defaultConfiguration,
        ...newConfig,
    };

    // Change the base url with the new updated config
    createTenantsApi(config);
};
