import React, { Component } from 'react';

import FavouriteItem from './FavouriteItem';

class Favourites extends Component {
    state = {
        editing: false,
        favouriteIcons: [
            { name: "Fav. 01", type: "icon_1" },
            { name: "Fav. 02", type: "icon_2" },
            { name: "Fav. 03", type: "icon_1" },
            { name: "Fav. 04", type: "icon_1" },
            { name: "Fav. 05", type: "icon_2" },
            { name: "Fav. 06", type: "icon_3" },
            { name: "Fav. 07", type: "icon_3" },
            { name: "Fav. 08", type: "icon_3" },
            { name: "Fav. 09", type: "icon_3" }
        ]
    }

    handleEditClicked = () => {
        this.setState({
            editing: !this.state.editing
        });
    }

    render () {
        // Set content to null
        let content = null;

        // Add content if we have favourite icons
        if(this.state.favouriteIcons) {
            // Initialize the index, required for accessing the favourite icon
            let index = 0;

            // Initialize the content array
            content = [];

            // Loop over the three rows
            for(let row = 0; row < this.state.favouriteIcons.length / 3; row++)
            {
                // Create an array to store the favourite icon jsx markup in
                let rowData = [];

                // Loop over the columns
                for(let column = 0; column < 3; column++) {
                    // Only add data if there is data
                    if(this.state.favouriteIcons.length > index) {
                        // Add the row data
                        rowData.push(
                            <FavouriteItem name={this.state.favouriteIcons[index].name} 
                                            type={this.state.favouriteIcons[index].type}
                                            editing={this.state.editing}
                                            key={index} />
                        );
                    }

                    // Increment the index
                    index++;
                }

                // Add the row to the content
                content.push(
                    <div key={row} className="row body-row mx-0 py-0">
                        {rowData}
                    </div>
                )
            }
        }
        
        return (
            <>
                {/* Title */}
                <div className="title-row favourites row mx-0">
                    <div className="col-3 px-0 mx-0 d-flex align-items-center">
                        <i className="mx-auto fas fa-th fa-2x text-white"></i>
                    </div>
                    <div className="col-6 px-0 py-0 mx-0 d-flex align-items-center text-center text-white">
                        <span className="title-text">Favorieten</span>
                    </div>
                    <div onClick={this.handleEditClicked} className="col-3 px-0 mx-0 d-flex align-items-center clickable">
                        <i className="mx-auto fas fa-cog title-sub-icon text-white"></i>
                    </div>
                </div>

                {/* Rows */}
                {content}
            </>
        )
    }
}

export default Favourites;