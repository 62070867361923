import React, { useState, useEffect } from 'react';
import { ManageTable, NoContent, ManageTableActions } from 'ht-gui';
import { useParams, useNavigate } from 'react-router-dom';
import { Translate } from '@hanssens/ht-translate';
import { Button } from '@material-ui/core';
import { useStore } from 'ht-store';

import adminApi from '../../../api/adminApi';
import DeleteModal from '../../Shared/DeleteModal';

import { routerPrefix } from '../../../routers/MainRouter';
import { routerPrefix as applicationPrefix } from '../../../routers/ApplicationRouter';

const ApplicationUriRightsOverview = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const { id, uriId } = useParams();
    const navigate = useNavigate();
    const [, setBreadcrumbs] = useStore('appadmin-breadcrumbs');
    const [currentApplication] = useStore('appadmin-currentApplication');

    const [data, setData] = useState(null);
    const [deletePopup, setDeletePopup] = useState(null);

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    useEffect(() => {
        adminApi()
            .get(`v1/rights/uriRight/uri/${uriId}?withRight=true`)
            .then((response) => {
                setData(
                    response.data.map((d) => {
                        return {
                            ...d,
                            applicationRightName: `${d.applicationRight.name}`,
                        };
                    })
                );
            })
            .catch((error) => {
                console.warn('An error occured during the fetch of the application parts', error);
            });
    }, [uriId]);

    useEffect(() => {
        setBreadcrumbs([
            { label: <Translate id='appAdmin.applications.breadcrumbs.applications' />, route: `${routerPrefix}${applicationPrefix}` },
            { label: currentApplication ? currentApplication.name : <Translate id='appAdmin.applications.breadcrumbs.application' /> },
            { label: <Translate id='appAdmin.applications.breadcrumbs.uris' />, route: `${routerPrefix}${applicationPrefix}/${id}/dashboard/uris` },
            { label: <Translate id='appAdmin.applications.breadcrumbs.rights' /> },
        ]);
    }, [setBreadcrumbs, id, currentApplication]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/
    const handleCreateClicked = (e) => {
        e.preventDefault();
        navigate(`${routerPrefix}${applicationPrefix}/${id}/dashboard/uris/${uriId}/rights/create`);
    };

    const handleEditClicked = (data) => {
        navigate(`${routerPrefix}${applicationPrefix}/${id}/dashboard/uris/${uriId}/rights/update/${data.id}`);
    };

    const handleDeleteClicked = (data) => {
        setDeletePopup({ object: data });
    };

    const handleConfirmDeleteClicked = (object, onSuccess, onError) => {
        adminApi()
            .delete(`v1/rights/uriRight/${object.id}`)
            .then((response) => {
                let currentUriRights = [...data];
                let index = currentUriRights.findIndex((t) => t.id === object.id);

                if (index === -1) {
                    return;
                }

                currentUriRights.splice(index, 1);

                setData(currentUriRights);
                onSuccess();
            })
            .catch((error) => {
                onError();
            });
    };

    const handleUriRightDeleted = (object) => {
        setDeletePopup(null);
    };

    /*********************************************************************/
    /* Managetable config
    /*********************************************************************/

    const componentsAfter = (
        <Button variant='contained' className='bg-success text-white rounded-0 ml-1' onClick={handleCreateClicked}>
            <Translate id='appAdmin.applications.uris.rights.createNew' />
        </Button>
    );

    const config = {
        columns: {
            applicationRightName: {
                label: <Translate id='appAdmin.applications.uris.rights.name' />,
            },
            actions: {
                label: 'Actions',
                component: ManageTableActions,
                props: {
                    actions: [
                        {
                            icon: 'fal fa-pencil',
                            hoverIcon: 'fas fa-pencil',
                            onClick: handleEditClicked,
                        },
                        {
                            icon: 'fal fa-trash',
                            hoverIcon: 'fas fa-trash',
                            onClick: handleDeleteClicked,
                        },
                    ],
                },
            },
        },
        sortBy: {
            by: 'name',
        },
        search: {
            componentsAfter: componentsAfter,
        },
        // onDoubleClick: handleDoubleClicked,
        noItemsComponent: (
            <NoContent
                title={<Translate id='appAdmin.applications.uris.rights.noEntries' />}
                description={<Translate id='appAdmin.applications.uris.rights.noEntriesDesc' />}
                backgroundColour='bg-dark-blue'
                icon='fas fa-shelves-empty fa-7x'
            />
        ),
        data: data,
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    let deleteModalContent = null;
    if (deletePopup) {
        let description = (
            <>
                <Translate id='appAdmin.applications.uris.rights.delete.areYouSureDelete' />
                <div>
                    <div>{deletePopup.object.applicationRightName}</div>
                </div>
            </>
        );
        deleteModalContent = (
            <DeleteModal
                object={deletePopup.object}
                onConfirmClicked={handleConfirmDeleteClicked}
                title={<Translate id='appAdmin.applications.uris.rights.delete.title' />}
                description={description}
                failed={<Translate id='appAdmin.applications.uris.rights.delete.failed' />}
                titleBarColor='bg-danger'
                confirmClasses={['bg-danger', 'text-white']}
                onClose={() => setDeletePopup(null)}
                onDeleted={handleUriRightDeleted}
            />
        );
    }

    return (
        <>
            {deleteModalContent}
            <ManageTable {...config} />
        </>
    );
};

export default ApplicationUriRightsOverview;
