import React from 'react';
import { Input, TitleBar, saveStatuses, Select } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';
import { getClasses } from 'ht-resize';

import { verifyProperty, verifyObject } from '../../../../utilities/utility';
import { uriRegex } from '../ApplicationUriCreate';

import { applicationUriRules } from './verificationRules';

import Property from '../../../Shared/Property';

const methods = [
    {
        value: 'GET',
        label: 'GET',
    },
    {
        value: 'HEAD',
        label: 'HEAD',
    },
    {
        value: 'POST',
        label: 'POST',
    },
    {
        value: 'PUT',
        label: 'PUT',
    },
    {
        value: 'DELETE',
        label: 'DELETE',
    },
    {
        value: 'PATCH',
        label: 'PATCH',
    },
];

const ApplicationUriGeneral = (props) => {
    const { applicationUri, setApplicationUri, status, error, uriError, applicationId, edit } = props;

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    let id = null;

    if (edit && applicationUri.id) {
        id = <Property label={<Translate id='appAdmin.applications.id' />} value={applicationUri.id} />;
    }

    return (
        <div className='col-12'>
            <div className='bg-white box-shadow'>
                <TitleBar label={<Translate id='appAdmin.applications.create.general' />} />
                <div className='p-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                            {id}
                            <Property label={<Translate id='appAdmin.applications.applicationId' />} value={applicationId} />
                            <Input
                                id='appAdmin.applications.uris.value'
                                value={applicationUri.value}
                                label={<Translate id='appAdmin.applications.uris.value' />}
                                error={
                                    (error && !verifyProperty(applicationUri.value, applicationUriRules.value)) ||
                                    (applicationUri.value && applicationUri.value.length > 2048) ||
                                    (uriError && !uriRegex.test(applicationUri.value))
                                }
                                required
                                onChange={(e) => setApplicationUri({ ...applicationUri, value: e.target.value })}
                                className='w-100 mb-3'
                                errorMessage={<Translate id='appAdmin.applications.uris.uriError' />}
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <Select
                                value={applicationUri.method}
                                options={methods}
                                onChange={(e) => setApplicationUri({ ...applicationUri, method: e.target.value })}
                                error={error && !verifyProperty(applicationUri.method, applicationUriRules.method)}
                                label={<Translate id='appAdmin.applications.uris.method' />}
                                id='appAdmin.applications.uris.method'
                                className='w-100'
                                required
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                        </div>
                    </div>

                    {/* Error */}
                    {error && !verifyObject(applicationUri, applicationUriRules) ? (
                        <div className='row'>
                            <div className='col-6 '>
                                <div className='alert alert-danger rounded-0 mb-0 mt-3'>
                                    <Translate id='appAdmin.applications.create.error' />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ApplicationUriGeneral;
