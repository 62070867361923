import React from 'react';
import { SelectButton } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';
import { useParams, useNavigate, useLocation, matchPath } from 'react-router-dom';

import { routerPrefix } from '../../../../routers/MainRouter';
import { routerPrefix as applicationPrefix } from '../../../../routers/ApplicationRouter';

const ButtonBar = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const match = matchPath({ path: '/appadmin/applications/:id/dashboard/:option/*' }, location.pathname);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/
    const handleButtonClicked = (value) => {
        navigate(`${routerPrefix}${applicationPrefix}/${id}/dashboard/${value}`);
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    let currentId = '';
    if (match && match.params && match.params.option) {
        currentId = match.params.option;
    }

    return (
        <div className='row'>
            <SelectButton
                width='25'
                label={<Translate id='appAdmin.applications.dashboard.buttons.rights' />}
                icon='fas fa-key'
                id='rights'
                currentId={currentId}
                onClick={handleButtonClicked}
                background='bg-danger'
                color='text-danger'
            />
            <SelectButton
                width='25'
                label={<Translate id='appAdmin.applications.dashboard.buttons.uris' />}
                icon='fas fa-link'
                id='uris'
                currentId={currentId}
                onClick={handleButtonClicked}
                background='bg-secondary'
                color='text-secondary'
            />
            <SelectButton
                width='25'
                label={<Translate id='appAdmin.applications.dashboard.buttons.parts' />}
                icon='fas fa-boxes-stacked'
                id='parts'
                currentId={currentId}
                onClick={handleButtonClicked}
                background='bg-primary'
                color='text-primary'
            />
            <SelectButton
                width='25'
                label={<Translate id='appAdmin.applications.dashboard.buttons.settings' />}
                icon='fas fa-gears'
                id='settings'
                currentId={currentId}
                onClick={handleButtonClicked}
                background='bg-info'
                color='text-primary'
            />
        </div>
    );
};

export default ButtonBar;
