import React from 'react';

import NotificationItem from './NotificationItem';

const notifications = () => {
    let notificationItems = [
        { id: 19875, title: "Notificatie 001", name: "Jane Doe", time: "10:19"},
        { id: 19885, title: "Notificatie 002", name: "Jane Doe", time: "10:21"},
        { id: 19895, title: "Notificatie 003", name: "Jane Doe", time: "10:23"},
        { id: 19905, title: "Notificatie 004", name: "Jane Doe", time: "10:25"},
        { id: 19915, title: "Notificatie 005", name: "Jane Doe", time: "10:27"},
        { id: 19925, title: "Notificatie 006", name: "Jane Doe", time: "10:29"},
        { id: 19935, title: "Notificatie 007", name: "Jane Doe", time: "10:30"},
        { id: 19945, title: "Notificatie 008", name: "Jane Doe", time: "10:30"},
        { id: 19955, title: "Notificatie 009", name: "Jane Doe", time: "10:30"},
        { id: 19965, title: "Notificatie 010", name: "Jane Doe", time: "10:30"},
        { id: 19975, title: "Notificatie 011", name: "Jane Doe", time: "10:30"},
        { id: 19985, title: "Notificatie 012", name: "Jane Doe", time: "10:30"},
        { id: 19995, title: "Notificatie 013", name: "Jane Doe", time: "10:30"},
        { id: 20005, title: "Notificatie 014", name: "Jane Doe", time: "10:30"},
        { id: 20015, title: "Notificatie 015", name: "Jane Doe", time: "10:30"},
        { id: 20025, title: "Notificatie 016", name: "Jane Doe", time: "10:30"},
        { id: 20035, title: "Notificatie 017", name: "Jane Doe", time: "10:30"},
        { id: 20045, title: "Notificatie 018", name: "Jane Doe", time: "10:30"},
        { id: 20055, title: "Notificatie 019", name: "Jane Doe", time: "10:30"},
        { id: 20065, title: "Notificatie 020", name: "Jane Doe", time: "10:30"},
    ]

    let content = notificationItems.map((item, index) => {
        return <NotificationItem key={index} id={item.id} title={item.title} name={item.name} time={item.time} />
    })

    return (
        <>
            {/* Title */}
            <div className="title-row notifications row mx-0">
                <div className="col-3 px-0 mx-0 d-flex align-items-center">
                    <i className="mx-auto far fa-bell fa-2x text-white"></i>
                </div>
                <div className="col-6 px-0 py-0 mx-0 d-flex align-items-center text-center text-white">
                    <span className="title-text">Notificaties</span>
                </div>
                <div className="col-3 px-0 mx-0 d-flex align-items-center">
                    <span className="title-sub-icon mx-auto fas fa-check-double text-white"></span>
                </div>
            </div>

            {/* Container */}
            <div className="notifications-container w-100">
                {content}
            </div>
        </>
    )
}

export default notifications;