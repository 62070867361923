import React from 'react';

const notificationItem = (props) => {
    return (
        <div className='row notification-row mx-0 px-0'>
            <div className='col-2 mx-0 px-0 d-flex justify-content-center align-items-center'>
                <div className='notification-border'></div>
                <i className='fas fa-user-circle fa-2x text-dark'></i>
            </div>
            <div className='col-8 mx-0 pl-1 pr-1 d-flex flex-column justify-content-center'>
                <div className='block-with-text'>
                    {props.id}: {props.title} door <strong>{props.name}</strong>
                </div>
            </div>
            <div className='col-2 mx-0 px-0'>
                <span className='text-muted text-small'>{props.time}</span>
            </div>
        </div>
    );
};

export default notificationItem;
