import axios from 'axios';

let instance = null;

export const createAxiosInstance = (config) => {
    let url = config.API_URI + 'api/';
    instance = axios.create({
        baseURL: url,
        headers: {
            'ht-tenantid': config.HT_TENANTID ?? '',
        },
    });

    // Add a request interceptor to add the bearer token
    instance.interceptors.request.use(
        (requestConfig) => {
            let user = sessionStorage.getItem('oidc.user:' + config.OIDC_BASE_URI + ':' + config.OIDC_CLIENT_ID);

            if (user != null) {
                user = JSON.parse(user);

                requestConfig.headers = {
                    ...requestConfig.headers,
                    Authorization: 'Bearer ' + user.access_token,
                };
            }
            return requestConfig;
        },
        (error) => {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    instance.CancelToken = axios.CancelToken;
    instance.isCancel = axios.isCancel;
};

const instanceFunction = () => instance;
export default instanceFunction;
