import React, { useEffect, useState } from 'react';
import { TitleBar, saveStatuses, SuggestiveSelect } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';
import { getClasses } from 'ht-resize';

import { verifyProperty, verifyObject } from '../../../../../utilities/utility';
import adminApi from '../../../../../api/adminApi';

import { applicationUriRightRules } from './verificationRules';

import Property from '../../../../Shared/Property';

const ApplicationUriRightGeneral = (props) => {
    const { applicationUriRight, setApplicationUriRight, status, error, applicationUriId, applicationId, edit, applicationUriRightId } = props;
    const [applicationRights, setApplicationRights] = useState(null);

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    useEffect(() => {
        let fetchUri = `v1/rights/applicationright/application/${applicationId}?uriId=${applicationUriId}`;

        if (edit) {
            fetchUri = `v1/rights/applicationright/application/${applicationId}?uriId=${applicationUriId}&uriRightId=${applicationUriRightId}`;
        }

        adminApi()
            .get(fetchUri)
            .then((response) => {
                let data = response.data.map((d) => {
                    return {
                        value: d.id,
                        label: d.name,
                    };
                });
                setApplicationRights(data);
            })
            .catch((err) => {
                console.warn('An error occured during the fetch of the application rights', err);
            });
    }, [applicationId, applicationUriId, edit, applicationUriRightId]);

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    let id = null;

    if (edit && applicationUriRight.id) {
        id = <Property label={<Translate id='appAdmin.applications.id' />} value={applicationUriRight.id} />;
    }

    return (
        <div className='col-12'>
            <div className='bg-white box-shadow'>
                <TitleBar label={<Translate id='appAdmin.applications.create.general' />} />
                <div className='p-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                            {id}
                            <Property label={<Translate id='appAdmin.applications.uris.rights.applicationUriId' />} value={applicationUriId} />

                            <SuggestiveSelect
                                id='appAdmin.applications.rights.applicationRight'
                                value={applicationUriRight.applicationRightId}
                                options={applicationRights ? applicationRights : []}
                                onChange={(event, value) => setApplicationUriRight({ ...applicationUriRight, applicationRightId: value })}
                                label={<Translate id='appAdmin.applications.uris.rights.applicationRight' />}
                                className='w-100 mb-3'
                                required
                                error={error && !verifyProperty(applicationUriRight.applicationRightId, applicationUriRightRules.applicationRightId)}
                                disabled={!applicationRights || status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                        </div>
                    </div>

                    {/* Error */}
                    {error && !verifyObject(applicationUriRight, applicationUriRightRules) ? (
                        <div className='row'>
                            <div className='col-6 '>
                                <div className='alert alert-danger rounded-0 mb-0 mt-3'>
                                    <Translate id='appAdmin.applications.create.error' />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ApplicationUriRightGeneral;
