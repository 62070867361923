import React from 'react';

// Import css
import './SidebarRight.scss';

const SidebarRight = (props) => {
    /********************************/
    /* RENDER
    /********************************/
    // Initialise the class names
    let classNames = ["bg-white", "bl-sm"];

    // Add toggled properties
    if (props.toggled) {
        classNames.push("toggled");
    }

    if (props.topbarToggled) {
        classNames.push("topbar-toggled");
    }

    return (
        <div id="sidebar-right" className={classNames.join(' ')}>
            <div className="d-flex justify-content-center mt-3">
                <h5>Placeholder</h5>
            </div>
        </div>
    );
}

export default SidebarRight;