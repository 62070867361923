import React, { useEffect, useState } from 'react';
import { PageTitle } from 'ht-gui';
import { useParams, useNavigate, Outlet, useLocation, matchPath } from 'react-router-dom';
import { Translate } from '@hanssens/ht-translate';
import { useStore } from 'ht-store';

import adminApi from '../../api/adminApi';
import { routerPrefix } from '../../routers/MainRouter';
import { routerPrefix as applicationPrefix } from '../../routers/ApplicationRouter';

import ButtonBar from '../../components/General/Applications/ApplicationDashboard/ButtonBar';

const ApplicationDashboard = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const match = matchPath({ path: '/appadmin/applications/:id/dashboard' }, location.pathname);
    const actionMatch = matchPath({ path: '/appadmin/applications/:id/dashboard/:item/:action/*' }, location.pathname);

    const [breadcrumbs, setBreadcrumbs] = useStore('appadmin-breadcrumbs');
    const [, setCurrentApplication] = useStore('appadmin-currentApplication');

    const [application, setApplication] = useState(null);
    /*********************************************************************/
    /* Init
    /*********************************************************************/
    useEffect(() => {
        adminApi()
            .get('v1/rights/application/' + id)
            .then((response) => {
                setCurrentApplication(response.data);
                setApplication(response.data);
            })
            .catch((error) => {
                console.warn('An error occured during the fetch of the application', error);
            });
    }, [id, setCurrentApplication]);

    useEffect(() => {
        if (match && application != null) {
            setBreadcrumbs([{ label: <Translate id='appAdmin.applications.breadcrumbs.applications' />, route: `${routerPrefix}${applicationPrefix}` }, { label: application.name }]);
        }
    }, [match, setBreadcrumbs, application]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/
    const handleBreadcrumClicked = (e, route) => {
        e.preventDefault();
        navigate(route);
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    let title = <Translate id='appAdmin.applications.dashboard.title' />;

    let action = null;
    if (actionMatch && actionMatch.params && actionMatch.params.action) {
        switch (actionMatch.params.action.toLowerCase()) {
            case 'create':
                action = <Translate id='appAdmin.create' />;
                break;
            case 'update':
                action = <Translate id='appAdmin.update' />;
                break;
            default:
                break;
        }
    }

    if (application && application.name) {
        let actionContent = null;
        if (action) {
            actionContent = <> - {action}</>;
        }
        title = (
            <>
                <Translate id='appAdmin.applications.dashboard.title' />: {application.name}
                {actionContent}
            </>
        );
    }

    return (
        <section id='appadmin-application-dashboard' className='main-scrollable p-4'>
            <PageTitle title={title} subTitle={<Translate id='appAdmin.applications.dashboard.subtitle' />} breadcrumbs={breadcrumbs} onLinkClicked={handleBreadcrumClicked} />

            {/* Header */}
            <div className='header'></div>

            {/* Button bar */}
            <ButtonBar />

            {/* Content */}
            <div className='mt-4'>
                <Outlet />
            </div>
        </section>
    );
};

export default ApplicationDashboard;
