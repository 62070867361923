import React from 'react';
import { useTranslate } from '@hanssens/ht-translate';
import { SidebarLeft as TenantsSidebarLeft } from '../../../library/index';

// Import components
import SidebarLeftItems from '../../components/Layout/SidebarLeft/SidebarLeftItems';

// Import translation file
import sidebarLeftTranslations from '../../translations/layout/sidebarLeft.json';

// Import css
import './SidebarLeft.scss';

const SidebarLeft = (props) => {
    /********************************/
    /* LOCAL STATE & PROPS
    /********************************/
    // Add the translation file
    useTranslate(sidebarLeftTranslations);

    /********************************/
    /* RENDER
    /********************************/
    // Set classnames
    let classNames = ['bg-dark-blue', 'text-white', 'br-sm', 'b-secondary'];
    if (props.toggled) {
        classNames.push('toggled');
    }

    // Define the links
    /*
     * Each array is basically a 'category'.
     * The items in a category are a 3-wide array:
     * - The first element is the link
     * - The second element is the name (or the translation key)
     * - The third element is the icon to display
     */
    // const generalLinks = [
    //     {
    //         link: '/dashboard',
    //         label: <Translate id='sidebarLeft.DASHBOARD' />,
    //         icon: 'fal fa-columns',
    //         hoverIcon: 'fas fa-columns',
    //     },
    //     {
    //         link: '/profile',
    //         label: <Translate id='sidebarLeft.PROFILE' />,
    //         icon: 'fal fa-user',
    //         hoverIcon: 'fas fa-user',
    //     },
    // ];
    // const subMenuLinks = [
    //     {
    //         link: '/subMenu1',
    //         label: <Translate id='sidebarLeft.SUB_MENU_1' />,
    //         icon: 'fal fa-ticket-alt',
    //         hoverIcon: 'fas fa-ticket-alt',
    //     },
    //     {
    //         link: '/subMenu2',
    //         label: <Translate id='sidebarLeft.SUB_MENU_2' />,
    //         icon: 'fal fa-calendar-alt',
    //         hoverIcon: 'fas fa-calendar-alt',
    //     },
    //     {
    //         link: '/subMenu3',
    //         label: <Translate id='sidebarLeft.SUB_MENU_3' />,
    //         icon: 'fal fa-project-diagram',
    //         hoverIcon: 'fas fa-project-diagram',
    //     },
    // ];

    return (
        <div id='sidebar-left' className={classNames.join(' ')}>
            <ul className='nav nav-pils flex-column'>
                <TenantsSidebarLeft first onClick={props.handleLinkClicked} container={SidebarLeftItems} />
            </ul>
        </div>
    );
};

export default SidebarLeft;
