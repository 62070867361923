import React from 'react';
import { Input, TitleBar, saveStatuses } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';
import { getClasses } from 'ht-resize';

import { verifyProperty, verifyObject } from '../../../../utilities/utility';

import { applicationRules } from './verificationRules';

import Property from '../../../Shared/Property';

const ApplicationGeneral = (props) => {
    const { application, setApplication, status, error, edit } = props;

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    let id = null;

    if (edit && application.id) {
        id = <Property label={<Translate id='appAdmin.applications.id' />} value={application.id} />;
    }

    return (
        <div className='col-12'>
            <div className='bg-white box-shadow'>
                <TitleBar label={<Translate id='appAdmin.applications.create.general' />} />
                <div className='p-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                            {id}

                            <Input
                                id='appAdmin.applications.name'
                                value={application.name}
                                label={<Translate id='appAdmin.applications.name' />}
                                error={(error && !verifyProperty(application.name, applicationRules.name)) || (application.name && application.name.length > 90)}
                                required
                                onChange={(e) => setApplication({ ...application, name: e.target.value })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <Input
                                id='appAdmin.applications.description'
                                value={application.description}
                                label={<Translate id='appAdmin.applications.description' />}
                                error={error && !verifyProperty(application.description, applicationRules.description)}
                                required
                                onChange={(e) => setApplication({ ...application, description: e.target.value })}
                                className='w-100'
                                rows={3}
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                        </div>
                    </div>

                    {/* Error */}
                    {error && !verifyObject(application, applicationRules) ? (
                        <div className='row'>
                            <div className='col-6 '>
                                <div className='alert alert-danger rounded-0 mb-0 mt-3'>
                                    <Translate id='appAdmin.applications.create.error' />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ApplicationGeneral;
