import React from 'react';

const Profile = () => {
    return (
        <>
            {/* Title */}
            <div className="title-row profile row mx-0">
                <div className="col-3 px-0 mx-0 d-flex align-items-center">
                    <i className="mx-auto fas fa-user-circle fa-2x text-white"></i>
                </div>
                <div className="col-6 px-0 py-0 mx-0 d-flex align-items-center text-center text-white">
                    <span className="title-text">Profiel</span>
                </div>
            </div>

            {/* Icon */}
            <div className="row mt-3 mx-0 py-0">
                <div className="col-12 profile-icon d-flex align-items-center justify-content-center">
                    <i className="default-profile-icon fas fa-user-circle text-dark"></i>
                </div>
            </div>

            {/* Information */}
            <div className="row mt-2 mx-0 py-0 text-center">
                <div className="col-12 text-center">
                    <h6 className="my-0">John Doe</h6>
                </div>
                <div className="col-12 mt-1 my-0 text-center text-secondary d-flex flex-column">
                    <span>Enterprise Ltd.</span>
                    <span className="text-muted text-function">R & D</span>
                </div>
            </div>

            <div className="row mt-3 mx-0 py-0">
                <div className="col-6">
                    <button className="btn btn-success text-white">Instellingen</button>
                </div>
                <div className="col-6">
                    <button className="btn btn-danger text-white">Uitloggen</button>
                </div>
            </div>
        </> 
    )
}

export default Profile;