import * as React from 'react';
import { Input, TitleBar, saveStatuses, Switch } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';
import { getClasses } from 'ht-resize';

import { verifyProperty, verifyObject } from '../../../../utilities/utility';
import { checkIfInputIsValidName } from './ApplicationSettingInputRegex';

import { applicationSettingRules } from './verificationRules';

import Property from '../../../Shared/Property';

const ApplicationSettingGeneral = (props) => {
    const { applicationSetting, setApplicationSetting, status, error, applicationId, edit } = props;

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    let id = null;

    if (edit && applicationSetting.id) {
        id = <Property label={<Translate id='appAdmin.applications.id' />} value={applicationSetting.id} />;
    }

    return (
        <div className='col-12'>
            <div className='bg-white box-shadow'>
                <TitleBar label={<Translate id='appAdmin.applications.create.general' />} />
                <div className='p-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                            {id}
                            <Property label={<Translate id='appAdmin.applications.applicationId' />} value={applicationId} />
                            <Input
                                id='appAdmin.applications.settings.name'
                                value={applicationSetting.name}
                                label={<Translate id='appAdmin.applications.settings.name' />}
                                error={error && (!verifyProperty(applicationSetting.name, applicationSettingRules.name) || !checkIfInputIsValidName(applicationSetting.name))}
                                errorMessage={<Translate id='appAdmin.applications.settings.nameError' />}
                                required
                                onChange={(e) => setApplicationSetting({ ...applicationSetting, name: e.target.value })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <div className='mb-3'>
                                <Switch
                                    label={<Translate id='appAdmin.applications.settings.required' />}
                                    id='appAdmin.applications.settings.required'
                                    checked={applicationSetting.required}
                                    onChange={(e) => setApplicationSetting({ ...applicationSetting, required: e.target.checked })}
                                    disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Error */}
                    {error && !verifyObject(applicationSetting, applicationSettingRules) ? (
                        <div className='row'>
                            <div className='col-6 '>
                                <div className='alert alert-danger rounded-0 mb-0 mt-3'>
                                    <Translate id='appAdmin.applications.create.error' />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ApplicationSettingGeneral;
