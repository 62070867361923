import React, { useEffect, useState } from 'react';
import { useStore } from 'ht-store';
import { Translate } from '@hanssens/ht-translate';
import { saveStatuses, SaveButton, saveTypes } from 'ht-gui';
import { useNavigate, useParams } from 'react-router-dom';
import { getClasses } from 'ht-resize';

import ApplicationUriRightGeneral from './ApplicationUriRightCreate/ApplicationUriRightGeneral';

import { routerPrefix } from '../../../../routers/MainRouter';
import { routerPrefix as applicationPrefix } from '../../../../routers/ApplicationRouter';
import adminApi from '../../../../api/adminApi';
import { applicationUriRightRules } from './ApplicationUriRightCreate/verificationRules';
import { verifyObject } from '../../../../utilities/utility';

const ApplicationUriRightCreate = (props) => {
    /***********************************************************************
     * State
     ***********************************************************************/
    const { id, uriId } = useParams();
    const navigate = useNavigate();
    const [, setBreadcrumbs] = useStore('appadmin-breadcrumbs');
    const [currentApplication] = useStore('appadmin-currentApplication');

    const [applicationUriRight, setApplicationUriRight] = useState({
        id: null,
        uriId: uriId,
        applicationRightId: null,
    });
    const [status, setStatus] = useState({
        saveStatus: saveStatuses.NEUTRAL,
    });
    const [error, setError] = useState(false);

    /***********************************************************************
     * Init
     ***********************************************************************/
    useEffect(() => {
        setBreadcrumbs([
            { label: <Translate id='appAdmin.applications.breadcrumbs.applications' />, route: `${routerPrefix}${applicationPrefix}` },
            { label: currentApplication ? currentApplication.name : <Translate id='appAdmin.applications.breadcrumbs.application' /> },
            { label: <Translate id='appAdmin.applications.breadcrumbs.uris' />, route: `${routerPrefix}${applicationPrefix}/${id}/dashboard/uris` },
            { label: <Translate id='appAdmin.applications.breadcrumbs.rights' />, route: `${routerPrefix}${applicationPrefix}/${id}/dashboard/uris/${uriId}/rights` },
            { label: <Translate id='appAdmin.applications.breadcrumbs.create' /> },
        ]);
    }, [id, setBreadcrumbs, uriId, currentApplication]);

    /*********************************************************************/
    /* Save & cancel functions
     /*********************************************************************/

    useEffect(() => {
        if (status.saveStatus === saveStatuses.SAVE_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.SAVING,
            });

            // Verify
            if (!verifyObject(applicationUriRight, applicationUriRightRules)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            adminApi()
                .post('v1/rights/uriRight', applicationUriRight)
                .then((response) => {
                    navigate(`${routerPrefix}${applicationPrefix}/${id}/dashboard/uris/${uriId}/rights`);

                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.SAVED,
                    });
                })
                .catch((error) => {
                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.ERROR,
                    });
                });
        }
    }, [status, navigate, applicationUriRight, id, uriId]);

    useEffect(() => {
        if (status.saveStatus === saveStatuses.CANCEL_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.CANCELING,
            });

            navigate(`${routerPrefix}${applicationPrefix}/${id}/dashboard/uris/${uriId}/rights`);
        }
    }, [status, navigate, id, uriId]);

    /***********************************************************************
     * Functions
     ***********************************************************************/
    const handleSaveStatusChanged = (s) => {
        setStatus({
            ...status,
            saveStatus: s,
        });
    };

    /***********************************************************************
     * Render
     ***********************************************************************/
    return (
        <>
            <ApplicationUriRightGeneral
                applicationUriRight={applicationUriRight}
                setApplicationUriRight={setApplicationUriRight}
                status={status}
                error={error}
                applicationId={id}
                applicationUriId={uriId}
            />
            <div className='col-12'>
                <div className='bg-white box-shadow p-3 mt-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                            <div className='w-50'>
                                <SaveButton showFeedback type={saveTypes.CONTROLLED} status={status.saveStatus} setStatus={handleSaveStatusChanged} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ApplicationUriRightCreate;
