import * as React from 'react';
import { useStore } from 'ht-store';
import { Translate } from '@hanssens/ht-translate';
import { saveStatuses, SaveButton, saveTypes } from 'ht-gui';
import { useNavigate, useParams } from 'react-router-dom';
import { getClasses } from 'ht-resize';

import ApplicationSettingGeneral from './ApplicationSettingCreate/ApplicationSettingGeneral';

import { routerPrefix } from '../../../routers/MainRouter';
import { routerPrefix as applicationPrefix } from '../../../routers/ApplicationRouter';
import adminApi from '../../../api/adminApi';
import { applicationSettingRules } from './ApplicationSettingCreate/verificationRules';
import { verifyObject } from '../../../utilities/utility';
import { checkIfInputIsValidName } from './ApplicationSettingCreate/ApplicationSettingInputRegex';

const ApplicationSettingCreate = () => {
    /***********************************************************************
     * State
     ***********************************************************************/
    const { id } = useParams();
    const navigate = useNavigate();
    const [, setBreadcrumbs] = useStore('appadmin-breadcrumbs');
    const [currentApplication] = useStore('appadmin-currentApplication');

    const [applicationSetting, setApplicationSetting] = React.useState({
        id: null,
        applicationId: id,
        name: '',
        required: false,
    });
    const [status, setStatus] = React.useState({
        saveStatus: saveStatuses.NEUTRAL,
    });
    const [error, setError] = React.useState(false);

    /***********************************************************************
     * Init
     ***********************************************************************/
    React.useEffect(() => {
        setBreadcrumbs([
            { label: <Translate id='appAdmin.applications.breadcrumbs.applications' />, route: `${routerPrefix}${applicationPrefix}` },
            { label: currentApplication ? currentApplication.name : <Translate id='appAdmin.applications.breadcrumbs.application' /> },
            { label: <Translate id='appAdmin.applications.breadcrumbs.settings' />, route: `${routerPrefix}${applicationPrefix}/${id}/dashboard/settings` },
            { label: <Translate id='appAdmin.applications.breadcrumbs.create' /> },
        ]);
    }, [id, setBreadcrumbs, currentApplication]);

    /*********************************************************************/
    /* Save & cancel functions
    /*********************************************************************/

    React.useEffect(() => {
        if (status.saveStatus === saveStatuses.SAVE_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.SAVING,
            });

            // Verify
            if (!verifyObject(applicationSetting, applicationSettingRules) || (applicationSetting.allowAllParts && !applicationSetting.applicationPartId)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            if (!checkIfInputIsValidName(applicationSetting.name)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            adminApi()
                .post('v1/rights/applicationSetting', applicationSetting)
                .then((response) => {
                    navigate(`${routerPrefix}${applicationPrefix}/${id}/dashboard/settings`);

                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.SAVED,
                    });
                })
                .catch((error) => {
                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.ERROR,
                    });
                });
        }
    }, [status, navigate, applicationSetting, id]);

    React.useEffect(() => {
        if (status.saveStatus === saveStatuses.CANCEL_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.CANCELING,
            });

            navigate(`${routerPrefix}${applicationPrefix}/${id}/dashboard/settings`);
        }
    }, [status, navigate, id]);

    /***********************************************************************
     * Functions
     ***********************************************************************/
    const handleSaveStatusChanged = (s) => {
        setStatus({
            ...status,
            saveStatus: s,
        });
    };

    /***********************************************************************
     * Render
     ***********************************************************************/

    return (
        <div className='row'>
            <ApplicationSettingGeneral applicationSetting={applicationSetting} setApplicationSetting={setApplicationSetting} status={status} error={error} applicationId={id} />
            <div className='col-12'>
                <div className='bg-white box-shadow p-3 mt-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                            <div className='w-50'>
                                <SaveButton showFeedback type={saveTypes.CONTROLLED} status={status.saveStatus} setStatus={handleSaveStatusChanged} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplicationSettingCreate;
