import React, { useState, useEffect, useCallback } from 'react';
import { ManageTable, ManageTableActions, NoContent } from 'ht-gui';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useStore } from 'ht-store';
import { Translate } from '@hanssens/ht-translate';

import Error from '../../../Shared/Error';
import Loading from '../../../Shared/Loading';
import IconDisplay from '../../../Shared/Managetable/IconDisplay';
import DeleteModal from '../../../Shared/DeleteModal';
import CheckDisplay from '../../../Shared/Managetable/CheckDisplay';
import ApplicationPart from '../../ApplicationRights/ApplicationPart';

import adminApi from '../../../../api/adminApi';

import { routerPrefix } from '../../../../routers/MainRouter';
import { routerPrefix as applicationPrefix } from '../../../../routers/ApplicationRouter';

const ApplicationRights = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const { id } = useParams();
    const navigate = useNavigate();

    const [, setBreadcrumbs] = useStore('appadmin-breadcrumbs');
    const [currentApplication] = useStore('appadmin-currentApplication');

    const [deletePopup, setDeletePopup] = useState(null);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [applicationParts, setApplicationParts] = useState(null);

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    const fetchData = useCallback(() => {
        adminApi()
            .get(`v1/rights/applicationright/application/${id}`)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    setError({ statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null });
                } else {
                    setError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                }
            });
    }, [id]);

    const fetchApplicationParts = useCallback(() => {
        adminApi()
            .get(`v1/rights/applicationpart/application/${id}`)
            .then((response) => {
                setApplicationParts(response.data);
            })
            .catch((err) => {
                console.warn('An error occured during the fetch of the application parts', err);
            });
    }, [id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        fetchApplicationParts();
    }, [fetchApplicationParts]);

    useEffect(() => {
        setBreadcrumbs([
            { label: <Translate id='appAdmin.applications.breadcrumbs.applications' />, route: `${routerPrefix}${applicationPrefix}` },
            { label: currentApplication ? currentApplication.name : <Translate id='appAdmin.applications.breadcrumbs.application' /> },
            { label: <Translate id='appAdmin.applications.breadcrumbs.rights' /> },
        ]);
    }, [setBreadcrumbs, currentApplication]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/
    const handleCreateClicked = (e) => {
        e.preventDefault();
        navigate(`${routerPrefix}${applicationPrefix}/${id}/dashboard/rights/create`);
    };

    const handleEditClicked = (data) => {
        navigate(`${routerPrefix}${applicationPrefix}/${id}/dashboard/rights/update/${data.id}`);
    };

    const handleDeleteClicked = (data) => {
        setDeletePopup({ object: data });
    };

    const handleConfirmDeleteClicked = (object, onSuccess, onError) => {
        adminApi()
            .delete(`v1/rights/applicationRight/${object.id}`)
            .then((response) => {
                let currentRights = [...data];
                let index = currentRights.findIndex((t) => t.id === object.id);

                if (index === -1) {
                    return;
                }

                currentRights.splice(index, 1);

                setData(currentRights);
                onSuccess();
            })
            .catch((error) => {
                onError();
            });
    };

    const handleApplicationRightDeleted = (object) => {
        setDeletePopup(null);
    };

    /*********************************************************************/
    /* Managetable config
    /*********************************************************************/

    const componentsAfter = (
        <Button variant='contained' className='bg-success text-white rounded-0 ml-1' onClick={handleCreateClicked}>
            <Translate id='appAdmin.applications.rights.createNew' />
        </Button>
    );

    const config = {
        columns: {
            icon: {
                label: <Translate id='appAdmin.applications.rights.icon' />,
                component: IconDisplay,
                props: {
                    icon: 'icon',
                },
            },
            displayNameCategory: {
                label: <Translate id='appAdmin.applications.rights.displayNameCategory' />,
            },
            displayName: {
                label: <Translate id='appAdmin.applications.rights.displayName' />,
            },
            name: {
                label: <Translate id='appAdmin.applications.rights.name' />,
            },
            description: {
                label: <Translate id='appAdmin.applications.rights.description' />,
            },
            rightSortOrder: {
                label: <Translate id='appAdmin.applications.rights.sortOrder' />,
            },
            applicationPartId: {
                label: <Translate id='appAdmin.applications.rights.applicationPart' />,
                component: ApplicationPart,
                props: {
                    applicationPartId: 'applicationPartId',
                    applicationParts: () => applicationParts,
                },
                execute: ['applicationParts'],
            },
            allowAllParts: {
                label: <Translate id='appAdmin.applications.rights.allowAllParts' />,
                component: CheckDisplay,
                props: {
                    checked: 'allowAllParts',
                },
            },
            actions: {
                label: 'Actions',
                component: ManageTableActions,
                props: {
                    actions: [
                        {
                            icon: 'fal fa-pencil',
                            hoverIcon: 'fas fa-pencil',
                            onClick: handleEditClicked,
                        },
                        {
                            icon: 'fal fa-trash',
                            hoverIcon: 'fas fa-trash',
                            onClick: handleDeleteClicked,
                        },
                    ],
                },
            },
        },
        sortBy: {
            by: 'name',
        },
        search: {
            componentsAfter: componentsAfter,
        },
        // onDoubleClick: handleDoubleClicked,
        noItemsComponent: (
            <NoContent
                title={<Translate id='appAdmin.applications.rights.noEntries' />}
                description={<Translate id='appAdmin.applications.rights.noEntriesDesc' />}
                backgroundColour='bg-dark-blue'
                icon='fas fa-shelves-empty fa-7x'
            />
        ),
        data: data,
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    // Error
    if (error) {
        return <Error code={error.statusCode} message={error?.data} />;
    }

    // Loading
    if (!data) {
        return <Loading title={<Translate id='appAdmin.applications.rights.loading' />} description={<Translate id='appAdmin.applications.rights.loadingDesc' />} />;
    }

    let deleteModalContent = null;
    if (deletePopup) {
        let description = (
            <>
                <Translate id='appAdmin.applications.rights.delete.areYouSureDelete' />
                <div>
                    <div>{deletePopup.object.name}</div>
                </div>
            </>
        );
        deleteModalContent = (
            <DeleteModal
                object={deletePopup.object}
                onConfirmClicked={handleConfirmDeleteClicked}
                title={<Translate id='appAdmin.applications.rights.delete.title' />}
                description={description}
                failed={<Translate id='appAdmin.applications.delete.failed' />}
                titleBarColor='bg-danger'
                confirmClasses={['bg-danger', 'text-white']}
                onClose={() => setDeletePopup(null)}
                onDeleted={handleApplicationRightDeleted}
            />
        );
    }

    return (
        <>
            {deleteModalContent}
            <ManageTable {...config} />
        </>
    );
};

export default ApplicationRights;
